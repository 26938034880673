<template>
  <!-- 主页 -->

  <div>
    <div class="Ptit">
      <strong class="tit">积分变更明细</strong>
    </div>
    <div>
      <ul class="mxList">
        <li v-for="(iam, iax) in mxList" :key="iax">
          <span class="time">{{ iam.changeTime }}</span>
          <span class="cz">{{ iam.note }}</span>
          <span class="bg">
            <!-- <span class="bgq">{{ iam.beforeChenge }}</span> -->
            <strong class="je"
              >{{ iam.changeType === '|' ? '+' : iam.changeType }}
              {{ iam.digital }}</strong
            >
            <span class="bgh">= {{ iam.afterChange }}</span>
          </span>
        </li>
      </ul>
      <div class="pagination">
        <el-pagination
          v-model:currentPage="pages"
          :page-size="20"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
// import { useRoute, useRouter } from 'vue-router'
import { getUsIntegraLlist } from '@/api/user'
import { sign } from '@/utils/storage'
// 列表
const pages = ref(1)
const total = ref(null)
const mxList = ref([])
const getDatas = async () => {
  var datas = await getUsIntegraLlist(sign({ page: pages.value, size: 20 }))
  mxList.value = datas.list
  total.value = datas.total
}
getDatas()

const handleSizeChange = ss => {
  pages.value = ss
  getDatas()
}
</script>

<style lang="scss" scoped>
.mxList li {
  padding: 1rem;
  font-size: 0.8rem;
  border-bottom: dotted 1px #ddd;
  display: flex;

  .time {
    width: 24%;
    color: #aaa;
  }
  .cz {
    display: inline-block;
    flex: 1;
  }
  .bg {
    .bgq,
    .bgh {
      color: #999;
    }
    .je {
      text-align: right;
      color: #d00;
      display: inline-block;
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
</style>
