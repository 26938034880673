import request from '@/utils/request'

/**
 * 上传文件
 */
export const uploadImg = data => {
  return request({
    url: '/api/uploadImg',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

/**
 * 上传文件
 */
export const uploadData = data => {
  return request({
    url: '/api/uploadData',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

/**
 * 提交认证
 */
export const postCertification = params => {
  return request({
    url: '/api/certification',
    method: 'post',
    params
  })
}

/**
 * 获取认证内容
 */
export const getCertificationShow = params => {
  return request({
    url: '/api/certificationShow',
    method: 'post',
    params
  })
}

/**
 * 发送完整数据
 */
export const postAddTask = params => {
  return request({
    url: '/api/addtask',
    method: 'post',
    data: params
  })
}

// export function postAddTask(data) {
//   return request({
//     url: '/api/addtask',
//     method: 'post',
//     headers: {
//       // 'Content-Type': 'multipart/form-data'
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     data
//   })
// }

/**
 * 获取 当前用户 积分
 */
export const getUsIntegralNum = params => {
  return request({
    url: '/api/usintegralnum',
    params
  })
}

/**
 * 获取 积分明细
 */
export const getUsIntegraLlist = params => {
  return request({
    url: '/api/usintegrallist',
    params
  })
}

// 充值
export const getUsPayChongzhi = params => {
  return request({
    url: '/api/uspaychongzhi',
    params
  })
}

/**
 * 获取 用户基本 资料
 */
export const getUserInfo = params => {
  return request({
    url: '/api/userinfo',
    params
  })
}

/**
 * 获取 实名  资料
 */
export const getUsInformation = params => {
  return request({
    url: '/api/usInformation',
    params
  })
}

/**
 * 提交  完善资料
 */
export const getPersonalInformation = params => {
  return request({
    url: '/api/personalInformation',
    params
  })
}

/**
 * 查询企业名称
 */
export const getSeachEnterpriseName = params => {
  return request({
    url: '/api/seachenterprisename',
    params
  })
}

/**
 * 添加企业
 */
export const getAddEnterprise = params => {
  return request({
    url: '/api/addenterprise',
    params
  })
}

/**
 * 查看企业
 */
export const getLookEnterprise = params => {
  return request({
    url: '/api/lookenterprise',
    params
  })
}

/**
 * 查看 申请加入的企业列表
 */
export const getauditEnterpriseList = params => {
  return request({
    url: '/api/auditenterpriselist',
    params
  })
}

// 申请加入 toapplyforenterprise
export const toApplyForEnterprise = params => {
  return request({
    url: '/api/toapplyforenterprise',
    params
  })
}

export const toApplyForEnterpriseShenhe = params => {
  return request({
    url: '/api/auditenterpriseshenhe',
    params
  })
}

// 我的消息列表
export const getUsMessageList = params => {
  return request({
    url: '/api/usmessagelist',
    params
  })
}

// 提现 发验证码 tixianphone
export const gettixianphone = params => {
  return request({
    url: '/api/tixianphone',
    params
  })
}
// 提现 历史银行卡
export const gettixiancardlist = params => {
  return request({
    url: '/api/tixiancardlist',
    params
  })
}

// 提现 提交
export const gettixianjijiao = params => {
  return request({
    url: '/api/tixianjijiao',
    params
  })
}

/**
 * 文章图片上传
 * @param {*} data
 * @returns
 */
//  export function articleImageUpload(data) {
//   return request({
//     url: '/cms/articleImage/upload',
//     method: 'post',
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     },
//     data
//   })
// }

/**
 * 提交认证
 */
//  export const uploadImg = params => {
//   return request({
//     url: '/api/uploadImg',
//     method: 'post',
//     params
//   })
// }
